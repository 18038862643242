<template>
    <base-review-card ref="review-card">
        <template #user>
            {{ editingItem.user.name }}
        </template>
        <template #rating>
            <review-stars
                :rating.sync="editingItem.rating"
                :editing="true"
            ></review-stars>
        </template>

        <template #description>
            <text-area
                :input-value.sync="editingItem.description"
                :current-value="editingItem.description"
                :label="'Tell us what you think'"
                :required="false"
            ></text-area>
        </template>

        <template #bottom-actions>
            <v-btn
                depressed
                dense
                text
                color="primary"
                class="mr-2"
                @click="cancelEditing()"
            >
                {{ $t("General.cancel") }}
            </v-btn>
            <v-btn
                depressed
                dense
                color="success darken2"
                class="mr-2"
                :disabled="editingItem.rating === 0"
                :loading="editStatus_Pending"
                @click="confirmEdit()"
            >
                {{ $t("General.submit") }}
            </v-btn>
        </template>
    </base-review-card>
</template>

<script>
import { apiStatus } from "@/api/constants/apiStatus";
import { apiStatusComputed } from "@/api/helpers/computedApiStatus";
import { withAsync } from "@/helpers/withAsync";
import { updateDihReview } from "@/api/adminApi";

export default {
    name: "DihReviews",

    props: {
        item: {
            type: Object,
            required: true,
        },
    },

    components: {
        BaseReviewCard: () => import("@/components/base/BaseReviewCard.vue"),
        ReviewStars: () => import("@/components/ReviewStars.vue"),
        TextArea: () => import("@/components/Inputs/TextArea.vue"),
    },

    data() {
        return {
            editStatus: apiStatus.Idle,
            editingItem: {...this.item},
        };
    },

    computed: {
        ...apiStatusComputed(["editStatus"]),
    },

    methods: {
        async confirmEdit() {
            this.editStatus = apiStatus.Pending;

            const payload = {
                id: this.editingItem.id,
                data: {
                    rating: this.editingItem.rating,
                    description: this.editingItem.description,
                },
            };

            const { response, error } = await withAsync(
                updateDihReview,
                payload
            );

            if (error) {
                this.editStatus = apiStatus.Error;
                return;
            }

            this.isEditing = false;
            this.editingItem = {};
            this.editStatus = apiStatus.Success;
            this.$emit("confirm-edit");
        },

        cancelEditing() {
            this.$emit("cancel-edit");
        },
    },
    watch: {
        item(newVal){
            this.editingItem = {...newVal};
        }
    }
};
</script>
